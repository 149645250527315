import {Link} from "@nextui-org/react";
import PostView from "@/components/shared/post-view";
import {CategoryHelper} from '@/components/helpers/category';

export default function categoryTemplate({data, categoryCode, isSubCate}) {
    const currentCategory = CategoryHelper.getCurrentCategory(categoryCode);

    if (data.total === 0 || !categoryCode) {
        return (<></>)
    }

    const cateDetailLink = isSubCate ? `/chuyen-de/${currentCategory.code}` : `/chuyen-muc/${currentCategory.code}`;
    return (
        <div className="bg-white rounded-[5px]">
            <div className="flex justify-start">
                <Link href={cateDetailLink}>
                    <h2 className="whitespace-nowrap text-primary font-bold text-3xl pt-5 pl-5">{currentCategory.name.toLocaleUpperCase()}</h2>
                </Link>
                <div className="hidden md:flex w-full pb-2 pl-2">
                    <p className="h-full w-full border-b-2 border-primary mr-2"></p>
                    <Link href={cateDetailLink}>
                        <p className="text-md text-primary text-nowrap whitespace-nowrap mb-[-35px] pr-5">
                            Xem thêm
                        </p>
                    </Link>
                </div>
            </div>

            <PostView data={data.value} code={'tin-giao-hoi-viet-nam'}/>

            <div className="sm:hidden flex justify-end">
                <Link href={cateDetailLink}>
                    <p className="text-md text-primary text-nowrap whitespace-nowrap pr-5">
                        Xem thêm
                    </p>
                </Link>
            </div>
        </div>
    );
}
